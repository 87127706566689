<script>
import {defineComponent} from 'vue'
import axios from "axios";

export default defineComponent({
  name: "ContractBalance",
  data() {
    return {
      balance: '',
      isLoading: true
    }
  },
  methods: {
    async getContractBalance() {
      this.isLoading = true
      let response = await axios.get(`/contract/balance/${this.$route.params.contract_slug}/`)
      this.balance = response.data
      this.isLoading = false
    }
  },
  watch: {
    '$route.params.contract_slug': {
      handler: async function (value, oldValue) {
        if (value && (value !== oldValue)) {
          await this.getContractBalance()
        }
      },
      deep: true,
      immediate: true
    }
  }
})
</script>

<template>
  <div class="py-3 px-4">
    <div class="row align-items-center justify-content-end">
      <div class="col-6" v-if="!isLoading">
        <div class="d-flex justify-content-end text-end flex-wrap gap-4 ms-auto">
          <div class="pe-3">
            <h4 class="mb-2 text-danger" v-if="balance.balance < 0">
              -${{ parseFloat(balance.balance.toString().split('-')[1]).toLocaleString('en-UK') }}
            </h4>
            <h4 class="mb-2 text-success" v-if="balance.balance >= 0">
              ${{ parseFloat(balance.balance).toLocaleString('en-UK') }}
            </h4>
            <h5 class="mb-0 text-truncate text-white">Total Balance</h5>
          </div>
          <div class="pe-3">
            <h4 class="text-success mb-2">+${{ parseFloat(balance.total_payment).toLocaleString('en-UK') }}</h4>
            <h5 class="mb-0 text-white">Payments Amount</h5>
          </div>
          <div class="pe-3">
            <h4 class="text-danger mb-2">-${{ parseFloat(balance.total_price).toLocaleString('en-UK') }}</h4>
            <h5 class="mb-0 text-white">Acts Prices</h5>
          </div>
        </div>
      </div>
      <div class="col-6 text-end" v-else-if="isLoading">
        <b-spinner variant="info"></b-spinner>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>