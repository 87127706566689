<script>
import {defineComponent} from 'vue'
import axios from "axios";
import Swal from "sweetalert2";

export default defineComponent({
  name: "ActContainerCreate",
  emits: ['update'],
  props: {
    act: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      submitting: false,
      lot_number: '',
      containers_or_wagons: '',
      codes: '',
      prices: ''
    }
  },
  computed: {
    formIsValid() {
      return this.prices !== ''
    }
  },
  methods: {
    async submitForm() {
      if (!this.formIsValid) {
        await Swal.fire({
          title: 'Warning!',
          text: 'Please fill all the fields',
          icon: 'warning',
          confirmButtonText: 'Ok',
          focusConfirm: true,
        })
        return
      }
      try {
        this.submitting = true
        await axios.post(`/contract/act_info/${this.act.id}/create/`, {
          lot_number : this.lot_number.trim(),
          containers_or_wagons: this.containers_or_wagons.split('\n'),
          codes: this.codes.split('\n'),
          prices: this.prices.split('\n'),
        })
        this.lot_number = ''
        this.containers = ''
        this.codes = ''
        this.prices = ''
        this.submitting = false
        document.querySelector('#ActInfoCreate .btn-close').click()
        this.$emit('update')
        await Swal.fire({
          title: 'Success!',
          text: 'Act Info Created Successfully',
          icon: 'success',
          confirmButtonText: 'Ok',
          focusConfirm: true,
        })
      } catch {
        this.submitting = false
        this.$emit('update')
        await Swal.fire({
          title: 'Error!',
          text: 'Something went wrong',
          icon: 'error',
          confirmButtonText: 'Ok',
          focusConfirm: true,
        })
      }
    }
  }
})
</script>

<template>
  <b-button variant="success" data-bs-toggle="modal"
            data-bs-target="#ActInfoCreate">
    <i class="mdi mdi-plus align-middle me-1"></i>
    Add Act Info
  </b-button>
  <div class="modal fade" id="ActInfoCreate" tabindex="-1" aria-labelledby="ActInfoCreateLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header p-3">
          <h5 class="modal-title" id="ActInfoCreateLabel">
            Add Act Info
            <span class="badge bg-primary align-middle ms-2">{{ act.name }}</span>
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body ">
          <form @submit.prevent="submitForm()">
            <div class="row text-start">
              <div class="col-12 mb-3">
                <label class="form-label text-dark">Lot Number</label>
                <input v-model="lot_number" class="form-control" placeholder="Enter lot number"/>
              </div>
              <div class="col-lg-4 mb-3">
                <label class="form-label text-dark">Containers/Wagons</label>
                <textarea v-model="containers_or_wagons" class="form-control" rows="10" placeholder="Containers list"/>
              </div>
              <div class="col-lg-4 mb-3">
                <label class="form-label text-dark">Codes</label>
                <textarea v-model="codes" class="form-control" rows="10" placeholder="Codes list"/>
              </div>
              <div class="col-lg-4 mb-3">
                <label class="form-label text-dark">Prices</label>
                <textarea v-model="prices" class="form-control" rows="10" placeholder="Prices list" required/>
              </div>
              <div class="col-12">
                <b-alert show variant="info" class="alert-borderless " role="alert">
                  <p class="mb-0 fs-14">
                    <strong> Quick Alert ! </strong> Please make sure to enter the containers, codes and prices in the
                    same order.
                  </p>
                  <span class="fw-semibold d-block cursor-pointer link-primary fs-14" data-bs-toggle="collapse"
                        data-bs-target="#collapseWithicon">
                    Read more
                    <i class="ri-arrow-right-line ms-1 align-bottom"></i>
                  </span>
                  <b-collapse id="collapseWithicon">
                    <p class="mt-2 fs-14">
                      The rows count in containers, codes and prices should be the same. <br>
                      Each row in containers, codes and prices should be separated by a new line. <br>
                      Each row in containers, codes and prices represents a single act info. <br>
                    </p>
                    <p class="mb-0 fw-bold fs-14">
                      Note that it is your responsibility to make sure that the data is correct.
                    </p>
                  </b-collapse>
                </b-alert>
              </div>
              <div class="col-12 text-end">
                <b-button v-if="!submitting" type="submit" variant="success">Submit</b-button>
                <b-button v-if="submitting" variant="success" disabled>Submitting ...</b-button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>