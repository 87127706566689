<template>
  <b-modal v-model="showModal" hide-footer title="Edit Act" class="v-modal-custom" centered>
    <form action="" @submit.prevent="editAct" class="text-dark" v-if="data">
      <div class="mb-3">
        <label for="actNameUpdate" class="form-label">
          Act Name
          <span class="text-danger">*</span>
        </label>
        <input v-model="data.name" id="actNameUpdate"
               type="text" class="form-control" placeholder="Act name">
      </div>
      <div class="mb-3">
        <label for="actCategoryUpdate" class="form-label">
          Category
          <span class="text-danger">*</span>
        </label>
        <Multiselect v-model="data.category" :options="categories"/>
      </div>
      <div class="mb-3">
        <label for="actDateUpdate" class="form-label">
          Date
          <span class="text-danger">*</span>
        </label>
        <input id="actDateUpdate" class="form-control" type="date" v-model="data.date">
      </div>
      <div class="mb-3">
        <label for="actCommentUpdate" class="form-label">
          Comment
          <span class="text-danger">*</span>
        </label>
        <textarea v-model="data.comment"
                  class="form-control" id="actCommentUpdate" rows="3" placeholder="Enter your comment"></textarea>
      </div>
      <div class="mb-3">
        <div class="form-check">
          <input v-model="save_current_file" class="form-check-input" type="checkbox" name="formCheckboxRight"
                 id="actSaveCurrentFile">
          <label class="form-check-label" for="actSaveCurrentFile">
            Save current file
          </label>
        </div>
      </div>
      <div class="mb-3" v-if="!save_current_file">
        <label for="actFileUpdate" class="form-label text-success">
          Choose New File
          <span class="text-danger">*</span>
        </label>
        <input id="actFileUpdate" type="file" class="form-control" @change="selectedFile">
      </div>
      <div class="mb-3" v-if="file">
        <div class="d-flex p-2">
          <div class="flex-grow-1">
            <div class="pt-1">
              <h5 class="fs-14 mb-1" data-dz-name="">
                {{ file.name }}
              </h5>
              <p class="fs-13 text-muted mb-0" data-dz-size="">
                <strong>{{ file.size / 1024 }}</strong> KB
              </p>
              <strong
                  class="error text-danger"
                  data-dz-errormessage=""
              ></strong>
            </div>
          </div>
          <div class="flex-shrink-0 ms-3">
            <button
                data-dz-remove=""
                class="btn btn-sm btn-danger"
                @click="deleteRecord"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
      <div class="text-end">
        <button type="submit" class="btn btn-success">Save act</button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import {updateAct} from "@/api/client/ClientApi.js"
import Swal from "sweetalert2";
import "@vueform/multiselect/themes/default.css";
import Multiselect from "@vueform/multiselect";

export default {
  emits: ['updated'],
  name: "document_edit",
  props: {
    categories: {
      type: Array,
      default: () => []
    }
  },
  components: {
    Multiselect
  },
  data() {
    return {
      showModal: false,
      file: null,
      save_current_file: true,
      act: {}
    }
  },
  computed: {
    data() {
      if (Object.keys(this.act).length === 0) return {}
      return {
        id: this.act.id,
        slug: this.act.slug,
        name: this.act.name,
        category: this.act.category.id,
        comment: this.act.comment,
        file: this.act.file,
        date: this.act.date,
      }
    },
  },
  methods: {
    openModal(act) {
      this.act = act
      this.showModal = true
    },
    async editAct() {

      if (this.save_current_file) {
        if (this.data.name.trim().length === 0 || !this.data.category || !this.data.date || this.data.comment.trim().length === 0) {
          return Swal.fire({
            title: 'Invalid Form',
            text: 'Please, fill all the fields',
            icon: 'warning'
          })
        }
      } else {
        if (this.data.name.trim().length === 0 || !this.data.category || !this.data.date || this.data.comment.trim().length === 0 || !this.file) {
          return Swal.fire({
            title: 'Invalid Form',
            text: 'Please, fill all the fields',
            icon: 'warning'
          })
        }
      }

      let response = await updateAct({
        id: this.data.id,
        slug: this.data.slug,
        name: this.data.name,
        comment: this.data.comment,
        contract: this.contract_id,
        category_id: this.data.category,
        date: this.data.date,
        file: this.save_current_file ? this.data.file : this.file
      })
      if (response.ok) {
        this.showModal = false
        this.save_current_file = true
        this.file = null
        this.$emit('updated')
        await Swal.fire({
          icon: 'success',
          title: 'Act updated successfully',
          showConfirmButton: false,
          timer: 2000
        })
      } else {
        await Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      }
    },
    selectedFile(event) {
      this.file = event.target.files[0];
    },
    deleteRecord() {
      this.file = null
      document.querySelector('#act_edit_modal #actFile').value = ''
    },
  },
}
</script>

<style scoped>

</style>