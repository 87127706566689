<template>
  <b-modal v-model="showModal" hide-footer title="Create Act" class="v-modal-custom" centered>
    <form action="" @submit.prevent="createAct" class="text-dark">
      <div class="mb-3">
        <label for="actName" class="form-label">
          Act Name
          <span class="text-danger">*</span>
        </label>
        <input v-model="name"
               type="text" class="form-control" id="actName" placeholder="Act name">
      </div>
      <div class="mb-3">
        <label for="actCategory" class="form-label">
          Category
          <span class="text-danger">*</span>
        </label>
        <Multiselect v-model="category_id" :options="categories" :searchable="true"/>
      </div>

      <div class="mb-3">
        <label for="actCategory" class="form-label">
          Date
          <span class="text-danger">*</span>
        </label>
        <input type="date" class="form-control" v-model="date">
      </div>

      <div class="mb-3">
        <label for="actComment" class="form-label">
          Comment
          <span class="text-danger">*</span>
        </label>
        <textarea v-model="comment"
                  class="form-control" id="actComment" rows="3" placeholder="Enter your comment"></textarea>
      </div>
      <div class="mb-3">
        <label for="actFile" class="form-label">
          File
          <span class="text-danger">*</span>
        </label>
        <input id="actFile" type="file" class="form-control" @change="selectedFile">
      </div>
      <div class="mb-3" v-if="file">
        <div class="d-flex p-2">
          <div class="flex-grow-1 overflow-hidden">
            <div class="pt-1 overflow-hidden">
              <h5 class="fs-14 mb-1 text-truncate" data-dz-name="" v-b-tooltip.hover :title="file.name">
                {{ file.name }}
              </h5>
              <p class="fs-13 text-muted mb-0" data-dz-size="">
                <strong>{{ (file.size / 1024).toFixed(2) }}</strong> KB
              </p>
              <strong
                  class="error text-danger"
                  data-dz-errormessage=""
              ></strong>
            </div>
          </div>
          <div class="flex-shrink-0 ms-3">
            <button
                data-dz-remove=""
                class="btn btn-sm btn-danger"
                @click="deleteRecord"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
      <div class="text-end">
        <button v-if="!isFormValid" type="submit" class="btn btn-success" disabled>Save act</button>
        <button v-if="isFormValid" :type="is_creating ? 'button' : 'submit'" class="btn btn-success">
          {{ is_creating ? 'Creating' : 'Save act' }}
        </button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import {createAct} from "@/api/client/ClientApi.js"
import Swal from "sweetalert2";
import "@vueform/multiselect/themes/default.css";
import Multiselect from "@vueform/multiselect";


export default {
  emits: ['created'],
  name: "act_create",
  props: {
    categories: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showModal: false,
      name: '',
      category_id: null,
      comment: '',
      file: null,
      date: null,
      is_creating: false,
      contract_slug: this.$route.params.contract_slug
    }
  },
  components: {
    Multiselect
  },
  computed: {
    isFormValid() {
      return this.name !== '' && this.file !== null && this.date !== null
    }
  },
  methods: {
    openModal() {
      this.showModal = true
    },
    async createAct() {
      this.is_creating = true
      let response = await createAct({
        name: this.name,
        category_id: this.category_id,
        comment: this.comment,
        contract_slug: this.contract_slug,
        file: this.file,
        date: this.date,
      })
      this.is_creating = false
      if (response.ok) {
        this.showModal = false
        this.name = ''
        this.category_id = null
        this.comment = ''
        this.file = null
        this.$emit('created')
        await Swal.fire({
          icon: 'success',
          title: 'Act created successfully',
          showConfirmButton: false,
          timer: 2000
        })
      } else {
        await Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      }
    },
    selectedFile(event) {
      this.file = event.target.files[0];
    },
    deleteRecord() {
      this.file = null
      try {
        document.getElementById('actFile').value = ''
      } catch {
        console.log("Can't find element with id actFile")
      }
    },
  },
}
</script>

<style scoped>

</style>