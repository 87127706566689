<script>
import {defineComponent} from 'vue'
import axios from "axios";
import Swal from "sweetalert2";

export default defineComponent({
  name: "ActInfoUpdate",
  emits: ['updated'],
  data() {
    return {
      isSaving: false,
      showModal: false,
      actInfo: {}
    }
  },
  computed: {
    infoComputed() {
      return this.actInfo
    }
  },
  methods: {
    openModal(actInfo) {
      this.actInfo = actInfo
      this.showModal = true
    },
    async updateActInfo() {
      this.isSaving = true
      try {
       await axios.put(`/contract/act_info/${this.infoComputed.id}/update/`, {
          lot_number: this.infoComputed.lot_number,
          container_or_wagon: this.infoComputed.container_or_wagon,
          code: this.infoComputed.code,
          price: this.infoComputed.price,
        })
        this.isSaving = false
        this.showModal = false
        this.$emit('updated')
        await Swal.fire({
          icon: 'success',
          title: 'Act Info Updated Successfully',
          showConfirmButton: false,
          timer: 3000
        })
      } catch {
        await Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      }
      this.isSaving = false
    }
  }
})
</script>

<template>
  <b-modal v-model="showModal" hide-footer title="Edit Act Info" class="v-modal-custom" centered>
    <form @submit.prevent="updateActInfo()">
      <div class="row">
        <div class="col-6">
          <div class="mb-3">
            <label for="infoLotNumber" class="form-label text-dark">Lot Number</label>
            <input v-model="infoComputed.lot_number" type="text" class="form-control"
                   placeholder="Enter container"
                   id="infoLotNumber">
          </div>
        </div>
        <div class="col-6">
          <div class="mb-3">
            <label for="infoContainer" class="form-label text-dark">Container/Wagon</label>
            <input v-model="infoComputed.container_or_wagon" type="text" class="form-control"
                   placeholder="Enter container"
                   id="infoContainer">
          </div>
        </div>
        <div class="col-6">
          <div class="mb-3">
            <label for="infoCode" class="form-label text-dark">Code</label>
            <input v-model="infoComputed.code" type="text" class="form-control" placeholder="Enter code"
                   id="infoCode">
          </div>
        </div>
        <div class="col-6">
          <div class="mb-3">
            <label for="infoPrice" class="form-label text-dark">Price ($)</label>
            <input v-model="infoComputed.price" type="number" class="form-control" placeholder="Enter price"
                   id="infoPrice" step="any">
          </div>
        </div>
        <div class="col-12 text-end">
          <div class="mb-1">
            <b-button v-if="!isSaving" type="submit" variant="success">Save Info</b-button>
            <b-button v-else-if="isSaving" type="submit" variant="success">Saving..</b-button>
          </div>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<style scoped>

</style>