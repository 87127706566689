<script>
import axios from "axios";
import debounce from 'lodash/debounce';
import ActCreate from "@/views/pages/accounts/counterparties/modals/ActCreate.vue";
import ActUpdate from "@/views/pages/accounts/counterparties/modals/ActUpdate.vue";
import ActDelete from "@/views/pages/accounts/counterparties/modals/ActDelete.vue";
import Payments from "@/views/pages/accounts/counterparties/components/Payments.vue";
import ActInfos from "@/views/pages/accounts/counterparties/components/ActInfos.vue";
import ContractBalance from "@/views/pages/accounts/counterparties/components/ContractBalance.vue";

export default {
  name: "ActsByContract",
  components: {
    ActCreate,
    ActUpdate,
    ActDelete,
    Payments,
    ActInfos,
    ContractBalance
  },
  data() {
    return {
      contract_request: {
        status: 'loading',
        data: null,
      },
      acts_request: {
        status: 'loading',
        order_by: '',
        order_by_sign: '',
        data: [],
        total_data: 0,
        total_pages: 0,
        per_page: 25,
        active_page: 1,
        search_text: '',
      },
      acts_table_headers: [
        {
          label: '#',
          field: 'index',
        },
        {
          label: 'Date',
          field: 'date',
          sortable: true,
        },
        {
          label: 'Name',
          field: 'name',
          sortable: true,
        },
        {
          label: 'Category',
          field: 'category',
          sortable: true,
        },
        {
          label: 'Price',
          field: 'total_price',
          sortable: true,
        },
        {
          label: 'Completed',
          field: 'is_completed',
          sortable: true,
        },
        {
          label: 'Actions',
          field: 'actions',
        }
      ],

      categories: []
    }
  },
  computed: {
    total_price_sum() {
      return this.acts_request.data.reduce((acc, act) => {
        return acc + parseFloat(act.total_price || 0)
      }, 0)
    },
    total_completed_acts() {
      return this.acts_request.data.filter(act => act.is_completed).length
    }
  },
  methods: {
    async getContractDetails() {
      this.contract_request.status = 'loading'
      try {
        let response = await axios.get(`/contract/${this.$route.params.contract_slug}/`)
        this.contract_request.data = response.data
        this.contract_request.status = 'success'
      } catch {
        this.contract_request.status = 'error'
      }
    },
    async getContractActs() {
      this.acts_request.status = 'loading'
      try {
        let query = {}
        query.offset = (this.acts_request.active_page - 1) * this.acts_request.per_page
        query.limit = this.acts_request.per_page

        if (this.acts_request.order_by) {
          query.ordering = this.acts_request.order_by
        }
        if (this.acts_request.search_text.trim().length) {
          query.search = this.acts_request.search_text.trim()
        }
        let response = await axios.get(`/contract/act/${this.$route.params.contract_slug}/list/`, {
          params: query
        })
        this.acts_request.total_data = response.data.count
        this.acts_request.total_pages = Math.ceil(response.data.count / this.acts_request.per_page)
        this.acts_request.data = response.data.results
        this.acts_request.status = 'success'
      } catch {
        this.acts_request.status = 'error'
      }
    },
    async tryToFetchActsByRouteSlug() {
      // this works when contract detail api failed to load contract details
      await this.getContractActs()
    },
    async searchTable() {
      await debounce(async () => {
        this.acts_request.active_page = 1
        await this.getContractActs('searchTable')
      }, 500)()
    },
    async goPage(page) {
      if (page === this.acts_request.active_page) return;
      this.acts_request.active_page = page
      await this.getContractActs('goPage')
    },
    async changePerPage(per_page) {
      this.acts_request.per_page = per_page || 10
      await this.getContractActs('changePerPage')
    },
    async onActChange() {
      await this.getContractActs('onActChange')
      await this.$refs.balance.getContractBalance()
    },
    async onPaymentChanged() {
      await this.getContractDetails()
      await this.$refs.balance.getContractBalance()
    },
    async onActInfoChanged() {
      await this.$refs.balance.getContractBalance()
    },
    orderBy(header) {
      if (header.sortable !== true) return;

      if (this.acts_request.order_by === header.field) {
        this.acts_request.order_by = `-${header.field}`
        this.acts_request.order_by_sign = '-'
      } else {
        this.acts_request.order_by = header.field
        this.acts_request.order_by_sign = '+'
      }
    },
    async getCategoryList() {
      try {
        let response = await axios.get('/counterparty/categories/')
        this.categories = response.data.results.map(category => {
          return {
            value: category.id,
            label: category.name
          }
        })
      } catch {
        console.log('error')
      }
    },

    seeActDetails(act) {
      this.$router.push({
        name: 'counterparty_profile_contracts_acts',
        params: {
          contract_slug: this.$route.params.contract_slug
        },
        query: {
          act: act.slug
        }
      })
    },
    openModalAndSeeDetails(act) {
      this.$refs.actUpdate.openModal(act);
      this.seeActDetails(act);
    },
    openDeleteModalAndSeeDetails(act) {
      this.seeActDetails(act);
      this.$refs.actDelete.openModal(act);
    },
    async downloadFile(path) {
      if (!path) return;
      let url = process.env.VUE_APP_ORDER_URL + path;
      fetch(url)
          .then(resp => resp.blob())
          .then(blobobject => {
            const blob = window.URL.createObjectURL(blobobject);
            const anchor = document.createElement('a');
            anchor.style.display = 'none';
            anchor.href = blob;
            anchor.download = url.toString().split('/')[url.toString().split('/').length - 1];
            document.body.appendChild(anchor);
            anchor.click();
            window.URL.revokeObjectURL(blob);
            this.is_downloading_file = false
          })
          .catch(() => alert('An error in downloading the file sorry'));
    },
    showSelectedActOnTop() {
      if (this.$route.query.act) {
        try {
          this.acts_request.search_text = this.$route.query.act.trim()
        } catch {
          alert("failed")
        }
      }
    }
  },
  watch: {
    'acts_request.order_by': {
      handler: function (newValue) {
        if (newValue) this.getContractActs('acts_request.order_by')
      },
      deep: true,
      immediate: true
    },
    'contract_request.status': {
      handler: function (newValue) {
        if (newValue === 'success' || newValue === 'error') {
          this.getContractActs('contract_request.status')
          this.showSelectedActOnTop()
        }
      },
      deep: true,
      immediate: true
    },
    'acts_request.search_text': {
      handler: function (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.searchTable()
        }
      }
    }
  },
  mounted() {
    this.getContractDetails()
    setTimeout(() => {
      this.getCategoryList()
    }, 1000)
  }
}
</script>

<template>

  <ActCreate ref="actCreate" :categories="categories" @created="onActChange()"/>
  <ActUpdate ref="actUpdate" :categories="categories" @updated="onActChange()"/>
  <ActDelete ref="actDelete" @deleted="onActChange()"/>

  <teleport to="#contract-balance">
    <ContractBalance ref="balance"/>
  </teleport>


  <section>

    <b-row class="gy-2 mb-3">

      <b-col>
        <b-card class="mb-0">
          <router-link :to="{name: 'counterparty_profile_main'}">
            <b-button variant="light" class="btn-border">
              <i class="mdi mdi-keyboard-backspace align-bottom me-1"></i>
              Back
            </b-button>
          </router-link>
        </b-card>
      </b-col>

    </b-row>

    <b-row class="align-content-start justify-content-start g-4">
      <b-col xl="5">
        <b-card no-body>

          <b-card-header class="d-flex justify-content-between align-items-center">
            <b-card-title class="mb-0">
              <div v-if="contract_request.status === 'loading'">
                <b-spinner></b-spinner>
              </div>

              <router-link :to="{name: 'counterparty_profile_main'}" v-else-if="contract_request.status === 'success'">
                <h3 class="mb-0">
                  <span class="badge badge-gradient-dark">{{ contract_request.data.name }}</span>
                </h3>
              </router-link>

              <b-alert v-else-if="contract_request.status === 'error'" size="sm" show variant="danger" class="mb-0">
                Couldn't load contract details
              </b-alert>

            </b-card-title>
            <b-button v-if="contract_request.status === 'success'"
                      variant="success" @click="this.$refs.actCreate.openModal()">
              <i class="ri-add-line align-middle me-1"></i> Add Act
            </b-button>
          </b-card-header>

          <b-card-body class="p-0">
            <div class="d-flex align-items-center p-3 justify-content-between mb-2">
              <div>
                <input v-if="contract_request.status === 'success'"
                       v-model="acts_request.search_text" class="form-control"
                       :placeholder="`Search for acts within ${contract_request.data.name}`">
                <input v-else class="form-control" placeholder="Search for acts" disabled>
              </div>
              <div>
                <h5 class="mb-0">
                  <b-badge variant="light" class="badge-label">
                    <i class="mdi mdi-circle-medium"></i>
                    Total acts: {{ acts_request.total_data }}
                  </b-badge>
                </h5>
              </div>
            </div>

            <div class="px-3">

              <div class="table-responsive mb-2">
                <table class="table table-striped">
                  <thead>
                  <tr>
                    <th class="sort act_header" :data-sort="header.label" scope="col"
                        v-for="header in acts_table_headers.filter(a => a.field !== 'actions')"
                        :key="`header_${header}`"
                    >
                      <div
                          @click="orderBy(header)"
                          :class="{ 'cursor-pointer': header.sortable }">
                        <span class="me-2">{{ header.label }}</span>
                        <template v-if="header.sortable">
                          <font-awesome-icon v-if="!acts_request.order_by.split('-').includes(header.field)"
                                             style="font-size: 10px"
                                             :icon="['fas', 'sort']"/>
                          <template
                              v-else-if="acts_request.order_by.split('-').includes(header.field)">
                            <i v-if="acts_request.order_by_sign === '+'"
                               class="ri-arrow-up-s-fill align-middle text-success"></i>
                            <i v-else-if="acts_request.order_by_sign === '-'"
                               class="ri-arrow-down-s-fill align-middle text-danger"></i>
                          </template>
                        </template>
                      </div>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <template v-if="acts_request.status === 'loading'">
                    <tr>
                      <th colspan="6">
                        <div class="d-flex align-items-center text-primary gap-3">
                          <b-spinner variant="primary"></b-spinner>
                          Loading acts...
                        </div>
                      </th>
                    </tr>
                  </template>
                  <template v-else-if="acts_request.status === 'success'">
                    <template v-if="acts_request.data.length > 0">
                      <template v-for="(act, index) in acts_request.data" :key="`act_${act}`">
                        <tr
                            @mouseover="act.hovered = true"
                            @mouseleave="act.hovered = false"
                            class="position-relative align-middle"
                            :class="{
                              'bg-soft-secondary': this.$route.query.act === act.slug
                            }"
                            @click="this.$route.query.act === act.slug ? this.$router.push({query: {}}) : seeActDetails(act)"
                        >
                          <th :class="{
                            'border-end': this.$route.query.act === act.slug
                            }" scope="row">{{ index + 1 }}
                          </th>
                          <td :class="{
                              'border-end': this.$route.query.act === act.slug
                            }"
                              style="min-width: 100px">{{ act.date }}
                          </td>
                          <td :class="{
                              'border-end': this.$route.query.act === act.slug
                            }"
                              style="min-width: 100px">{{ act.name }}
                          </td>
                          <td :class="{
                              'border-end': this.$route.query.act === act.slug
                            }"
                              style="min-width: 100px">{{ act.category ? act.category.name || '-' : '-' }}
                          </td>
                          <td :class="{
                              'border-end': this.$route.query.act === act.slug
                            }"
                              style="min-width: 100px">
                            <i class="bx bx-dollar"></i>
                            {{
                              parseFloat(act.total_price || 0).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              })
                            }}
                          </td>
                          <td style="min-width: 125px" class="text-center">
                            <i class="fs-5 align-middle" :class="{
                              'text-success ri-checkbox-circle-line': act.is_completed,
                              'text-danger ri-indeterminate-circle-line': !act.is_completed,
                            }"></i>
                          </td>
                          <td v-if="act.hovered"
                              class="floating-table-actions p-1 px-3">
                            <div class="d-flex align-items-center h-100 justify-content-center gap-2">
                              <VTooltip>
                                <i @click.stop="downloadFile(act.file)"
                                   class="bx bxs-download align-middle cursor-pointer rounded-2 p-2"></i>
                                <template v-slot:popper>Download act file</template>
                              </VTooltip>
                              <VTooltip>
                                <i @click.stop="openModalAndSeeDetails(act)"
                                   class="bc bx bxs-pencil align-middle text-primary cursor-pointer rounded-2 p-2"></i>
                                <template v-slot:popper>Edit this act</template>
                              </VTooltip>
                              <VTooltip>
                                <i @click.stop="openDeleteModalAndSeeDetails(act)"
                                   data-bs-toggle="modal" data-bs-target="#DeleteActModal"
                                   class="bx bx bxs-trash-alt align-middle text-danger cursor-pointer rounded-2 p-2"></i>
                                <template v-slot:popper>Delete this act</template>
                              </VTooltip>
                            </div>
                          </td>
                        </tr>
                      </template>
                      <tr class="text-center">
                        <td colspan="4"></td>
                        <th class="bg-light">$ {{
                            total_price_sum.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            }) || 0
                          }}
                        </th>
                        <th class="bg-light">
                          <span v-b-tooltip.hover title="Total completed acts"
                                class="fw-medium text-success">{{ total_completed_acts }}</span> of
                          {{ (acts_request.data || []).length }}
                        </th>
                      </tr>
                    </template>
                    <template v-else>
                      <tr>
                        <th colspan="5">
                          <div class="d-flex align-items-center text-primary gap-3">
                            <i class="mdi mdi-folder-multiple-outline fs-1"></i>
                            <div>
                              <h5 class="mb-0">No acts found</h5>
                              <small type="button" class="mb-0" @click="this.$refs.actCreate.openModal()">
                                Create an act to get started
                                <i class="mdi mdi-arrow-right"></i>
                              </small>
                            </div>
                          </div>
                        </th>
                      </tr>
                    </template>
                  </template>
                  <template v-if="acts_request.status === 'error'">
                    <tr>
                      <th colspan="6">
                        <b-alert show variant="danger" class="mb-0">
                          <p class="mb-0">Something went wrong. Please try again later.</p>
                        </b-alert>
                      </th>
                    </tr>
                  </template>
                  </tbody>
                </table>
              </div>

              <div v-if="acts_request.total_data > 0">
                <p>
                  <i class="bx bx-info-circle align-middle fs-5 me-1 text-secondary"></i>
                  Click on the row to see act's infos
                </p>
              </div>

              <div v-if="acts_request.total_data > 0"
                   class="d-flex justify-content-between gap-3 py-2">
                <div>
                  <div class="btn-group">
                    <button type="button" class="btn btn-light dropdown-toggle"
                            data-bs-toggle="dropdown" aria-expanded="false">
                      {{ acts_request.per_page }}
                    </button>
                    <div class="dropdown-menu dropdownmenu-info">
                      <a class="dropdown-item" v-for="per_page in [10, 25, 50, 100]" :key="`per_page_${per_page}`"
                         :class="{
                              'text-info bg-soft-info fw-bold': per_page === acts_request.per_page,
                          }"
                         @click="changePerPage(per_page)"
                      >
                        {{ per_page }}
                      </a>
                    </div>
                  </div>
                </div>
                <ul class="pagination pagination-rounded ">
                  <li class="page-item" :class="{'disabled': acts_request.active_page === 1}">
                    <a class="page-link"><i class="mdi mdi-chevron-left"></i></a>
                  </li>
                  <li
                      v-for="page in acts_request.total_pages" :key="`page_${page}`"
                      class="page-item" :class="{
                        'active': page === acts_request.active_page,
                        'cursor-pointer': page !== acts_request.active_page,
                      }"
                      @click="goPage(page)"
                  >
                    <a class="page-link">{{ page }}</a>
                  </li>
                  <li class="page-item" :class="{'disabled': acts_request.active_page === acts_request.total_pages}">
                    <a class="page-link"><i class="mdi mdi-chevron-right"></i></a>
                  </li>
                </ul>
              </div>

            </div>

          </b-card-body>

        </b-card>
      </b-col>
      <b-col xl="7">
        <ActInfos v-if="this.$route.query.act" @updated="onActInfoChanged()"/>
        <Payments v-else @update="onPaymentChanged()"/>
      </b-col>
    </b-row>

  </section>

</template>

<style scoped>
.floating-table-actions {
  position: absolute;
  right: 0;
  z-index: 1;
  height: 100%;
  background: rgba(48, 48, 48, 0.08);
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(48, 48, 48, 0.3);
}
</style>