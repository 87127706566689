<script>
import {tasksMethods} from "@/state/helpers";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "ConnectOrders",
  props: {
    act_id: {
      type: Number,
      required: true
    },
    act_name: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      showModal: false,
      user_choice: 'codes',
      exclude_connected: false
    }
  },
  methods: {
    ...tasksMethods,
    openModal() {
      this.showModal = true
    },

    async handleFormSubmit() {
      if (this.user_choice === 'codes') {
        await this.connectOrdersByCode()
      } else if (this.user_choice === 'containers_wagons') {
        await this.connectOrdersByContainer()
      } else {
        alert("Option not selected !")
      }
    },

    async sendToTaskQueue(task_id) {
      await this.addTask({
        id: task_id,
        title: `Connect Act ${this.act_name} with orders`,
        check_url: "/contract/check_task_status/",
        every: 2500,
      })
    },

    async connectOrdersByContainer() {
      try {
        let response = await axios.post(`/contract/act_info/${this.act_id}/connect/`, {
          all: !this.exclude_connected
        })
        await this.sendToTaskQueue(response.data.task_id)
      } catch (error) {
        await Swal.fire({
          title: error.response.data.message ? error.response.data.message : 'Something went wrong',
          html: 'Please check the lot numbers <br> and try again',
          icon: 'error',
          confirmButtonText: 'Ok',
          focusConfirm: true,
        })
      }
    },
    async connectOrdersByCode() {
      try {
        let response = await axios.post(`/contract/act_info/${this.act_id}/connect/code/`, {
          all: !this.exclude_connected
        })
        await this.sendToTaskQueue(response.data.task_id)
      } catch (error) {
        await Swal.fire({
          title: error.response.data.message ? error.response.data.message : 'Something went wrong',
          html: 'Please check the codes <br> and try again',
          icon: 'error',
          confirmButtonText: 'Ok',
          focusConfirm: true,
        })
      }
    },

  },
}
</script>

<template>
  <b-button @click="openModal" variant="primary" class="me-2">
    <i class="mdi mdi-link"></i>
    Connect Orders
  </b-button>

  <b-modal v-model="showModal" hide-footer title="Connect with Orders" modal-class="fade"
           class="v-modal-custom" hide-header
           centered>

    <div class="p-4">
      <p class="fs-3">Try to connect 👉</p>

      <div
          class="d-flex flex-column gap-2 mb-3">

        <div class="form-check form-radio-outline form-radio-secondary ">
          <input v-model="user_choice" class="form-check-input" type="radio" id="checkAllCodes" value="codes">
          <label class="form-check-label fs-5" for="checkAllCodes">
            All act infos <span class="text-secondary">by codes</span>
          </label>
        </div>

        <div class="form-check form-radio-outline form-radio-secondary">
          <input v-model="user_choice" class="form-check-input" type="radio" id="checkAllContainersWagons"
                 value="containers_wagons">
          <label class="form-check-label fs-5" for="checkAllContainersWagons">
            All act infos <span class="text-secondary">by containers or wagons</span>
          </label>
        </div>

      </div>


      <!-- Primary Alert -->
      <b-alert dismissible show variant="secondary" class="alert-additional fade show mb-3" role="alert">
        <div class="alert-body">
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          <div class="d-flex">
            <div class="flex-shrink-0 me-3">
              <i class="ri-user-smile-line fs-16 align-middle"></i>
            </div>
            <div class="flex-grow-1">
              <h5 class="alert-heading text-semibold">Good to know !</h5>
              <p class="mb-0 fs-5">
                We'll connect with your orders <strong> in the background </strong>,
                so feel free to carry on with your tasks. We'll
                <strong>notify</strong> you once it's done. Thanks!
              </p>
            </div>
          </div>
        </div>
      </b-alert>

      <!-- Custom Checkboxes Color -->
      <div class="form-check form-check-secondary mb-2">
        <input class="form-check-input" type="checkbox" id="excludeConnected" v-model="exclude_connected">
        <label class="form-check-label fs-5" for="excludeConnected">
          Exclude connected {{ user_choice.split('_').join(' ') }}
        </label>
      </div>


      <b-button @click="handleFormSubmit()"
                variant="outline-secondary" size="lg" class="w-100">
        Confirm & Submit
        <i class="mdi mdi-arrow-right"></i>
      </b-button>
    </div>
  </b-modal>
</template>

<style scoped>

</style>