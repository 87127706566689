<script>
import {defineComponent} from 'vue'
import Table from "@/components/tables/table.vue";
import ActInfoCreateModal from "@/views/pages/accounts/counterparties/modals/ActInfoCreate.vue";
import ActInfoUpdateModal from "@/views/pages/accounts/counterparties/modals/ActInfoUpdate.vue";
import ActInfoDeleteModal from "@/views/pages/accounts/counterparties/modals/ActInfoDelete.vue";
import ActInfoUpdateSystemPrice from "@/views/pages/accounts/counterparties/components/ActInfoUpdateSystemPrice.vue";
import ActInfoConnectOrders from "@/views/pages/accounts/counterparties/modals/ConnectOrders.vue"
import ActInfoDisconnect from "@/views/pages/accounts/counterparties/modals/ActInfoDisconnect.vue"
import axios from "axios";

export default defineComponent({
  name: "ActInfos",
  emits: ['updated'],
  components: {
    Table,
    ActInfoCreateModal,
    ActInfoUpdateModal,
    ActInfoDeleteModal,
    ActInfoConnectOrders,
    ActInfoUpdateSystemPrice,
    ActInfoDisconnect
  },
  data() {
    return {
      headers: [
        {
          field: 'order',
          label: 'order',
          align: 'center',
          sortable: true,
          excel_data: (act) => {
            return act.order ? act.order.number : '--'
          }
        },
        {
          field: 'lot_number',
          label: 'lot number',
          align: 'center',
          sortable: true,
        },
        {
          field: 'container_or_wagon',
          label: 'container/wagon',
          align: 'center',
          sortable: true,
        },
        {
          field: 'code',
          label: 'code',
          align: 'center',
          sortable: true,
        },
        {
          field: 'price',
          label: 'price',
          align: 'center',
          sortable: true,
        },
        {
          field: 'price_from_system',
          label: 'Price From System',
          align: 'center',
          sortable: true,
        },
        {
          field: 'actions',
          label: 'actions',
          align: 'center',
        },
      ],
      act_info: {},
      orderPathByOrderType: {
        'container': '/orders/container/view/',
        'wagon': '/orders/wagon/view/',
        'wagon_empty': '/orders/empty-wagon/view/'
      },
      is_loading_act_details: false,
    }
  },
  methods: {
    onActInfoChanged() {
      this.$refs.table.getData()
      this.$emit('updated')
    },
    async getActInfo() {
      this.is_loading_act_details = true
      try {
        let contract_slug = this.$route.params.contract_slug
        let act_slug = this.$route.query.act
        let response = await axios.get(`contract/detail/${contract_slug}/${act_slug}/`)
        this.act_info = response.data
      } catch {
        this.act_info = {}
      }
      this.is_loading_act_details = false
    }
  },
  watch: {
    '$route.query.act': {
      handler: function (newValue) {
        if (newValue) {
          this.act_info = {}
          this.getActInfo()
        } else {
          this.act_info = {}
        }
      },
      immediate: true,
      deep: false
    },
  },
})
</script>

<template>
  <ActInfoUpdateModal ref="actInfoUpdateModal" @updated="onActInfoChanged()"/>
  <ActInfoDeleteModal ref="actInfoDeleteModal" @deleted="onActInfoChanged()"/>
  <ActInfoDisconnect ref="actInfoDisconnect" @updated="onActInfoChanged()"/>

  <div class="mb-4" v-if="act_info.id">
    <Table ref="table" searchable selectable
           :name="act_info.name" :headers="headers" per_page_location="bottom"
           :url="`/contract/act_info/${this.act_info.slug}/`">
      <template v-slot:top-right>
        <ActInfoUpdateSystemPrice :act_id="act_info.id" @update="onActInfoChanged()"/>
        <ActInfoConnectOrders
            @update="onActInfoChanged()"
            :act_id="act_info.id"
            :act_name="act_info.name"
        />
        <ActInfoCreateModal :act="act_info" @update="onActInfoChanged()"/>
      </template>

      <template v-slot:functions="{rows}">
        <b-button @click="this.$refs.actInfoDisconnect.openModal(rows)"
            variant="outline-danger">
          Disconnect ({{ rows.length}})
        </b-button>
      </template>

      <template v-slot:price="{row: actInfo}">
        <span class="fw-medium" :class="{
          'text-success': parseFloat(actInfo.price).toFixed(2) === parseFloat(actInfo.price_from_system).toFixed(2),
          'text-danger': parseFloat(actInfo.price).toFixed(2) !== parseFloat(actInfo.price_from_system).toFixed(2),
        }">
            ${{ parseFloat(actInfo.price || 0).toLocaleString('en-UK') }}
        </span>
      </template>

      <template v-slot:container_or_wagon="{row: actInfo}">
        <template v-if="actInfo.order">
          <router-link
              :to="{
            path: orderPathByOrderType[actInfo.order.order_type] + actInfo.order.number + '/',
            query: {
              [actInfo.order.order_type === 'container' ? 'container': 'wagon']: actInfo.container_or_wagon
            }
          }">
            {{ actInfo.container_or_wagon }}
          </router-link>
        </template>
        <span v-else>
          {{ actInfo.container_or_wagon || '--' }}
        </span>
      </template>

      <template v-slot:price_from_system="{row: actInfo}">
        <span class="fw-medium" :class="{
          'text-success': parseFloat(actInfo.price).toFixed(2) === parseFloat(actInfo.price_from_system).toFixed(2),
          'text-danger': parseFloat(actInfo.price).toFixed(2) !== parseFloat(actInfo.price_from_system).toFixed(2),
        }">
            ${{ actInfo.price_from_system || 0 }}
        </span>
      </template>

      <template v-slot:order="{row: actInfo}">
        <template v-if="actInfo.order">
          <router-link :to="{path: orderPathByOrderType[actInfo.order.order_type] + actInfo.order.number + '/'}">
          <span class="badge badge-soft-primary">
            {{ actInfo.order.number }}
          </span>
          </router-link>
        </template>
        <span v-else>--</span>
      </template>
      <template v-slot:code_exists="{row: actInfo}">
      <span class="badge" :class="{
        'bg-success': actInfo.code_exists,
        'bg-danger': !actInfo.code_exists,
      }">
      {{ actInfo.code_exists ? 'Yes' : 'No' }}
      </span>
      </template>
      <template v-slot:actions="{row: actInfo}">
        <b-button-group class="mt-4 mt-md-0" role="group" size="sm" aria-label="Basic example">
          <b-button variant="light" class="p-0"
                    @click="this.$refs.actInfoUpdateModal.openModal(actInfo)"
          >
            <div class="ps-1">
              <i class="ri-pencil-fill align-bottom px-1"></i>
            </div>
          </b-button>
          <b-button variant="light"
                    data-bs-toggle="modal"
                    data-bs-target="#DeleteActInfoModal"
                    @click="this.$refs.actInfoDeleteModal.openModal(actInfo)"
          >
            <i class="ri-delete-bin-7-fill align-bottom text-danger"></i>
          </b-button>
        </b-button-group>

      </template>
    </Table>
  </div>
  <div v-else class="text-center py-5 my-5">
    <b-spinner variant="primary" class="mb-3"></b-spinner>
    <h5>Loading...</h5>
  </div>
</template>

<style scoped>

</style>