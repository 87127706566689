<template>
  <b-modal v-model="showModal" hide-footer title="Delete Act" class="v-modal-custom" centered>
    <h5 class="fw-normal mb-3 lh-base">Are you sure you want to delete <span class="fw-bolder text-danger me-1">{{
        act.name
      }}</span>?</h5>
    <form @submit.prevent="deleteAct()">
      <input v-model="confirmation_text" class="form-control mb-3" placeholder="Yes">
      <div class="text-end">
        <b-button v-if="!is_deleting" variant="danger"
                  type="submit"
                  :disabled="confirmation_text.trim().toUpperCase() !== 'YES'"
        >
          <i class="bx bx bxs-trash-alt align-middle me-1"></i>
          Yes, Delete
        </b-button>
        <b-spinner v-else variant="danger" class="mb-0"></b-spinner>
      </div>
    </form>
  </b-modal>
</template>

<script>
import {deleteActBySlug} from "@/api/client/ClientApi.js"
import Swal from "sweetalert2";

export default {
  emits: ['deleted'],
  name: "document_edit",
  data() {
    return {
      showModal: false,
      confirmation_text: '',
      act: {},
      is_deleting: false
    }
  },
  methods: {
    openModal(act) {
      this.showModal = true
      this.act = act
    },
    async deleteAct() {
      if (this.confirmation_text.trim().toUpperCase() !== 'YES') return;
      this.is_deleting = true
      let response = await deleteActBySlug(this.data.id)
      this.is_deleting = false
      if (response.ok) {
        this.$emit('deleted')
        this.showModal = false
        await Swal.fire({
          icon: 'success',
          title: 'Act deleted successfully',
          showConfirmButton: false,
          timer: 2000
        })
      } else {
        await Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      }
    }
  },
  computed: {
    data() {
      return this.act || {}
    }
  },
}
</script>

<style scoped>

</style>