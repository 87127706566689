<template>
  <b-modal v-model="showModal" hide-footer title="Delete Act Info" class="v-modal-custom" centered>
    <h5 class="fw-normal mb-2 lh-base">Are you sure you want to delete this act info ?
    </h5>
    <div class="table-responsive py-3">
      <table class="table table-borderless table-nowrap mb-0">
        <thead>
        <tr>
          <th scope="col">Order</th>
          <th scope="col">Lot Number</th>
          <th scope="col">{{ container_or_wagon }}</th>
          <th scope="col">Code</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <th scope="row">{{ (act_info.order ? act_info.order.number : '-') || '-' }}</th>
          <td>{{ act_info.lot_number || '-' }}</td>
          <td>{{ act_info.container_or_wagon || '-' }}</td>
          <td>{{ act_info.code || '-' }}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <form class="mt-3" @submit.prevent="deleteActInfo()">
      <input v-model="confirmation_text" class="form-control mb-3" placeholder="Yes">
      <div class="text-end">
        <b-button v-if="!is_deleting" variant="danger" type="submit"
                  :disabled="confirmation_text.trim().toUpperCase() !== 'YES'">
          <i class="bx bx bxs-trash-alt align-middle me-1"></i>
          Yes, Delete
        </b-button>
        <b-spinner v-else variant="danger" class="mb-0"></b-spinner>
      </div>
    </form>
  </b-modal>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";

export default {
  emits: ['deleted'],
  name: "document_edit",
  data() {
    return {
      showModal: false,
      confirmation_text: '',
      act_info: {},
      is_deleting: false
    }
  },
  methods: {
    openModal(act_info) {
      this.confirmation_text = ''
      this.showModal = true
      this.act_info = act_info
    },
    async deleteActInfo() {
      if (this.confirmation_text.trim().toUpperCase() !== 'YES') return;
      try {
        await axios.delete(`/contract/act_info/${this.data.id}/delete/`)
        this.$emit('deleted')
        await Swal.fire({
          icon: 'success',
          title: 'Act Info Deleted Successfully',
          showConfirmButton: false,
          timer: 2000
        })
      } catch {
        await Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      }
    }
  },
  computed: {
    data() {
      return this.act_info || {}
    },
    container_or_wagon() {
      if (
          this.act_info
          && this.act_info.container_or_wagon
          && this.act_info.container_or_wagon.length > 3
          && this.act_info.container_or_wagon[3].toUpperCase() === 'U'
      ) {
        return 'Container'
      } else {
        return 'Wagon'
      }
    }
  },
}
</script>

<style scoped>

</style>