<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "ActInfoDisconnect",
  emits: ['updated'],
  data() {
    return {
      showModal: false,
      selected_rows: [],
      confirmed: false
    }
  },
  methods: {
    openModal(selected_rows) {
      this.selected_rows = selected_rows || []
      this.showModal = true
    },
    async disconnectActInfos() {
      try {
        let Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 5000
        });
        let act_info_ids = this.selected_rows.map(row => row.id)
        await axios.put(`/contract/act_info/disconnect/from_order/`, {
          act_info_ids: act_info_ids
        })
        this.$emit('updated')
        await Toast.fire({
          icon: 'success',
          title: 'Act infos disconnected successfully'
        })
        this.showModal = false
      } catch {
        await Swal.fire({
          icon: 'error',
          title: 'Something went wrong'
        })
        this.showModal = false
      }
    }
  },
}
</script>

<template>
  <b-modal v-model="showModal" title="Disconnect orders" hide-footer centered>
    <!-- Info Alert -->
    <b-alert show variant="info" class="border-0 " role="alert">
      <strong> Attention </strong> You're about to <b>disconnect</b> the selected act infos from their orders. Are you
      sure you want
      to proceed?
    </b-alert>

    <div class="form-check form-check-info mb-3">
      <input v-model="confirmed" class="form-check-input" type="checkbox" id="formCheck11">
      <label class="form-check-label" for="formCheck11">
        Yes, I'm sure
      </label>
    </div>

    <b-button :disabled="!confirmed" variant="danger" class="w-100"
              @click="disconnectActInfos()"
    >
      Disconnect ({{ selected_rows.length }})
    </b-button>
  </b-modal>
</template>

<style scoped>

</style>