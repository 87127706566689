<script>
import {defineComponent} from 'vue'
import axios from "axios";
import Swal from "sweetalert2";

export default defineComponent({
  name: "ActInfoUpdateSystemPrice",
  emits: ['update'],
  props: {
    act_id: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      confirmed: false,
      request_success: false,
      is_loading: false
    }
  },
  methods: {
    async updatePrice() {
      this.is_loading = true
      try {
        await axios.post(`/contract/act_info/${this.act_id}/update_order_price/`)
        this.request_success = true
        this.confirmed = false
        this.$emit('update')
        await Swal.fire({
          title: 'Success!',
          text: 'System Prices Updates Successfully',
          icon: 'success',
          confirmButtonText: 'Ok',
          focusConfirm: true,
        })
      } catch {
        this.request_success = false
        await Swal.fire({
          title: 'Error!',
          text: 'Something went wrong',
          icon: 'error',
          confirmButtonText: 'Ok',
          focusConfirm: true,
        })
      }
      this.is_loading = false
    }
  }
})
</script>

<template>
  <template v-if="!confirmed">
    <b-button variant="outline-primary" class="me-2" @click="confirmed=true">Update System Price</b-button>
  </template>
  <template v-else>
    <b-button-group size="md" role="group" class="me-2" v-if="!is_loading">
      <b-button variant="success" @click="updatePrice()">
        Confirm
        <i class="bx bx-check align-middle fs-15 fw-bold"></i>
      </b-button>
      <b-button variant="light" @click="confirmed=false">
        Back
        <i class="bx bx-x align-middle fs-15"></i>
      </b-button>
    </b-button-group>
    <button v-else type="button" class="btn btn-success btn-load me-2">
      <span class="d-flex align-items-center">
        <span class="flex-grow-1 me-2">
        Updating...
        </span>
        <span class="spinner-grow flex-shrink-0" role="status">
        <span class="visually-hidden">Updating...</span>
      </span>
      </span>
    </button>
  </template>
</template>

<style scoped>

</style>